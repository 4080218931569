<template>
  <TTView>
    <VRow>
      <VCol>
        <NoDataStub
          :title="$t('home.surveyIsNotAccessible')"
          title-class="mt-4 mb-3"
          :subtitle="$t('home.youCanSeeOtherSurveys')"
          subtitle-class="mb-4"
          img-name="Robot.png"
          :button-title="$t('home.SeeAllSurveys')"
          :show-button="true"
          button-color="tt-secondary"
          button-size="large"
          class="stub pb-0"
          data-test-label="stub"
          @button-action="redirectToEngagement"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TTView from '@/components/ui/TTView.vue';
import NoDataStub from '@/components/common/NoDataStub.vue';

export default {
  name: 'Home',

  components: {
    TTView,
    NoDataStub,
  },
  methods: {
    redirectToEngagement() {
      const url = new URL('app/user_surveys', process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS);
      window.location.replace(url.href);
    },
  },
};
</script>
